
/*body[data-background-color=dark], .table thead th {*/
/*    border-bottom-width: 2px;*/
/*    font-weight: 600;*/
/*    color: whitesmoke;*/
/*}*/

/*body[data-background-color=dark], th, tbody{*/
/*    color: whitesmoke;*/
/*}*/

body[data-background-color=dark] .table-striped>tbody>tr:nth-of-type(odd)>*{
    color: white;
    background-color: #212529 !important;
}

body[data-background-color=dark] .table-striped>tbody>tr:nth-of-type(even)>*{
    color: wheat !important;
}

body[data-background-color=dark] .table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
 }

