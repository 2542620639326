.form-floating-label .placeholder {
    position: absolute !important;
    padding: 0.375rem 0.75rem !important;
    transition: all .2s;
    opacity: .8 !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    top: 12px !important;
    background: white;
}

.td_select{
    color: black;
    width: 50px;
}

.td_select{
    color: whitesmoke;
    width: 50px;
    border: 0;
}

body[data-background-color=dark] .td_select{
    color: whitesmoke;
    width: 50px;
    background-color: #1f283e;
    border: 0;
}

.edit_input{
    border: 0;
}
body[data-background-color=dark] .edit_input{
    background-color: #1f283e;
}




.on_edit_input{
    border-bottom: 1px solid black;
    padding-bottom: 1px;
}

body[data-background-color=dark] .on_edit_input{
    background-color: black;
}

body[data-background-color=dark] th{
    color: white;
}


body[data-background-color=dark] .rdt_TableCol{
    background-color: #1a2035;
    color: whitesmoke;
}

body[data-background-color=dark] .rdt_TableRow{
    background-color: #1f283e;
    color: whitesmoke;
}

body[data-background-color=dark] .rdt_Pagination{
    background-color: #1f283e;
    color: whitesmoke;
}


body[data-background-color=dark] .jriwbs>svg{
    background-color: white;
    opacity: 1;
}

body[data-background-color=dark] .gUHoGv{
    background-color: #1a2035;
    opacity: 1;
    color: wheat;
}

.form-control-search {
    font-size: 14px;
    border-color: #ebedf2;
    padding: .375rem .75rem !important;
    height: inherit !important;
}

button{
    margin-right: 5px !important;
}

button:hover{
    transform: scale(1.1, 1.1);
}
