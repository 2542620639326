/*@import '~antd/dist/antd.css';*/

.dlfHg div:first-child {
     white-space: inherit!important;
    overflow: hidden;
    text-overflow: ellipsis;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-modal-root .ant-modal-wrap {
    z-index: 9999;
}