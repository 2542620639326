span[data-notify="dismiss"] .alert-danger {
    transition: all 0.5s ease-in-out 0s;
}

.alert [data-notify=icon]::before {
    line-height: 35px;
    font-size: 22px;
    display: block;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
}

.top-left {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    top: 20px;
    left: 20px;
}

.top-right {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    right: 20px;
}

.top-center {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    top: 20px;
    left: 0px;
    right: 0px;
}

.bottom-left {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    bottom: 20px;
    left: 20px;
}

.bottom-center {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    bottom: 20px;
    left: 0px;
    right: 0px;
}

.bottom-right {
    display: inline-block;
    margin: 0px auto;
    position: fixed !important;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1060;
    bottom: 20px;
    right: 20px;
}
