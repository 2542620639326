.sidebar .nav>.nav-item a svg, .sidebar[data-background-color=white] .nav>.nav-item a svg {
    color: #8d9498;
    margin-right: 15px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
    float: left;
    font-size: 18px;
    line-height: 30px;
}

.sidebar.sidebar-style-2 .nav .nav-item a {
    padding: 8px 10px;
    border-radius: 5px;
    text-decoration: none;
}

.sidebar .user .info a, .sidebar[data-background-color=white] .user .info a {
    white-space: nowrap;
    display: block;
    position: relative;
    text-decoration: none;
}
.caret_trans{
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.mr-2, .mx-2 {
    margin-right: 0.5rem!important;
}

.float-left {
    float: left!important;
}

