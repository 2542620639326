.main-panel{
    overflow: auto;
}
.main-panel>.page-inner{
    min-height: calc(100% - 123px);
}
div[data-background-color=dark] .main-panel {
    color: rgb(189 195 208 / 82%) !important;
}
div[data-background-color=dark] .main-panel {
    color: rgb(189 195 208 / 82%)!important;
}
div[data-background-color=dark] {
    background: #1a2035
}

div[data-background-color=dark] {
    background: #1a2035
}

div[data-background-color=dark] .main-header {
    box-shadow: 0 0 5px #121727
}

div[data-background-color=dark] .main-panel {
    color: rgba(169, 175, 187, .82) !important
}

div[data-background-color=dark] .main-panel label {
    color: #fff !important
}

div[data-background-color=dark] .card, div[data-background-color=dark] .list-group-item, div[data-background-color=dark] .row-card-no-pd, div[data-background-color=dark] .timeline > li > .timeline-panel {
    background: #1a2035 !important;
}

div[data-background-color=dark] .card-pricing2 {
    background: #202940 !important
}

div[data-background-color=dark] .row-card-no-pd [class*=col] .card:before {
    background: rgba(181, 181, 181, .1) !important
}

div[data-background-color=dark] .breadcrumbs, div[data-background-color=dark] .card .card-action, div[data-background-color=dark] .card .card-footer, div[data-background-color=dark] .card .card-header, div[data-background-color=dark] .card-profile .user-stats [class^=col], div[data-background-color=dark] .conversations .messages-form, div[data-background-color=dark] .list-group .list-group-item, div[data-background-color=dark] .mail-wrapper .mail-content .email-head, div[data-background-color=dark] .mail-wrapper .mail-content .email-sender, div[data-background-color=dark] .mail-wrapper .mail-content .inbox-div .email-list .email-list-item, div[data-background-color=dark] .main-panel .page-divider, div[data-background-color=dark] .page-with-aside .page-aside, div[data-background-color=dark] .separator-dashed, div[data-background-color=dark] .separator-dot, div[data-background-color=dark] .separator-solid, div[data-background-color=dark] .table td, div[data-background-color=dark] .table th, div[data-background-color=dark] .timeline > li > .timeline-panel {
    border-color: rgba(181, 181, 181, .1) !important
}

div[data-background-color=dark] .timeline > li > .timeline-panel:before {
    border-left-color: rgba(181, 181, 181, .1);
    border-right-color: rgba(181, 181, 181, .1)
}

div[data-background-color=dark] .timeline > li > .timeline-panel:after {
    border-left-color: #202940;
    border-right-color: #202940
}

div[data-background-color=dark] .breadcrumbs li a, div[data-background-color=dark] .page-title {
    color: rgba(169, 175, 187, .82)
}

div[data-background-color=dark] .page-category {
    color: #828282
}

div[data-background-color=dark] .card-title, div[data-background-color=dark] .card-title a, div[data-background-color=dark] .card-title a:focus, div[data-background-color=dark] .card-title a:hover {
    color: #fff
}

div[data-background-color=dark] .card-category {
    color: #8b92a9
}

div[data-background-color=dark] .card-danger, div[data-background-color=dark] .card-default, div[data-background-color=dark] .card-info, div[data-background-color=dark] .card-primary, div[data-background-color=dark] .card-secondary, div[data-background-color=dark] .card-success, div[data-background-color=dark] .card-warning {
    color: #fff
}

div[data-background-color=dark] .card-danger .card-category, div[data-background-color=dark] .card-danger .card-title, div[data-background-color=dark] .card-default .card-category, div[data-background-color=dark] .card-default .card-title, div[data-background-color=dark] .card-info .card-category, div[data-background-color=dark] .card-info .card-title, div[data-background-color=dark] .card-primary .card-category, div[data-background-color=dark] .card-primary .card-title, div[data-background-color=dark] .card-secondary .card-category, div[data-background-color=dark] .card-secondary .card-title, div[data-background-color=dark] .card-success .card-category, div[data-background-color=dark] .card-success .card-title, div[data-background-color=dark] .card-warning .card-category, div[data-background-color=dark] .card-warning .card-title {
    color: #fff
}

div[data-background-color=dark] .nav-pills .nav-link:not(.active) {
    background: #fff
}

div[data-background-color=dark] .card-pricing .specification-list li {
    border-color: #373d4c
}

div[data-background-color=dark] .input-group-text {
    border-color: #2f374b !important;
    background-color: #1f283e;
    color: #fff
}

div[data-background-color=dark] .input-solid {
    background: #363b4c !important;
    border-color: #363b4c !important
}

div[data-background-color=dark] .list-group .list-group-item-text, div[data-background-color=dark] .list-group-messages .list-group-item-title a {
    color: inherit
}

div[data-background-color=dark] .footer {
    border-top: 1px solid #293247;
    background: #1f283e
}

div[data-background-color=dark] .form-control, div[data-background-color=dark] .form-group-default, div[data-background-color=dark] .select2-container--bootstrap .select2-selection {
    background-color: #1a2035;
    color: #fff;
    border-color: #2f374b
}

div[data-background-color=dark] .bootstrap-tagsinput {
    background: 0 0
}

div[data-background-color=dark] .selectgroup-button {
    border: 1px solid #2f374b
}

div[data-background-color=dark] .conversations .message-header {
    background: #1a2035;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .07)
}

div[data-background-color=dark] .conversations .conversations-content {
    color: #575962;
    border-color: #2e364a
}

div[data-background-color=dark] .mail-wrapper .mail-content .inbox-div .email-list .email-list-item.unread {
    background: #1f283e !important
}

div[data-background-color=dark] .mail-wrapper .mail-content .inbox-div .email-list .email-list-item:hover {
    background: #171e2f !important
}

div[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav > li.active, div[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav > li:focus, div[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav > li:hover {
    background: rgba(0, 0, 0, .03)
}

div[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav > li.active > a {
    color: #b9babf !important
}

div[data-background-color=dark] .board {
    color: #575962
}

div[data-background-color=dark] .table {
    color: papayawhip !important;
}
div[data-background-color=dark] .table:hover {
    color: whitesmoke !important;
}