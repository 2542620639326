
/* body[data-background-color=dark] .el-loading-spinner .path{
    stroke: white;
  } */
  /* body[data-background-color=dark] */
  .bg{

    margin: 0px;
    inset:0px;
    display: block;
    position: absolute;
    z-index: 657;
    background-color: rgba(255, 255, 255, 0.5);
  }
  body[data-background-color=dark] .bgcolor{
    /* background: rgba(68, 65, 65, 0.5); */
    background-color: rgba(68, 65, 65, 0.5);

  }