.select_bottom_line > .css-13cymwt-control {
    border-width: 0 0 1px !important;
    border-color: #30374b;
}

.table-search-input.form-control {
    padding: 0.6rem 0 !important;
}

body[data-background-color=dark] .css-1nmdiq5-menu{
    background-color: #202940 !important;
    color: whitesmoke !important;
}
.css-1dimb5e-singleValue{
    color: inherit !important;
}
body[data-background-color=dark] .css-1dimb5e-singleValue {
    color: hsl(0deg 7.15% 73.24%) !important;
}
body[data-background-color=dark] .css-1jqq78o-placeholder {
    color: hsl(0deg 7.15% 73.24%) !important;
}

body[data-background-color=dark] .select__option--is-focused{
    background-color: #2684FF !important;
}
body[data-background-color=dark] .select__option:hover{
    background-color: #3b4253 !important;
}

.css-1nmdiq5-menu{
    z-index: 10000 !important;
}

